import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { FsModule } from "@pyzar/fs.module"

import { MerchantApi } from "@backend/merchant.api"
import { MerchantEmagApi } from "@backend/merchant.emag.api"
import { PartnerApi } from "@backend/partner.api"

import { EuropeerCommonModule } from "../common.module"
import { ProductModule } from "../product.module"
import { PartnerGridComponent } from "./partner-grid.component"
import { PartnerSheetComponent } from "./partner-sheet.component"
import { PartnerScreen } from "./partner.screen"
import { PartnerBasicDataComponent } from "./sheet/basic-data.component"
import { PickupAddressComponent } from "./sheet/pickup-address.component"
import { ProductLinkUploaderWndComponent } from "./sheet/product-uploader.component"
import { PartnerProductsComponent } from "./sheet/products.component"

export const ROUTES: Route[] = [{ path: "partners", component: PartnerScreen }]

@NgModule({
    imports: [
        CommonModule,
        NzModule,
        PartnerApi,
        MerchantApi,
        MerchantEmagApi,
        EuropeerCommonModule,
        ProductModule,
        FsModule
    ],
    declarations: [
        PartnerGridComponent,
        PartnerScreen,
        PartnerSheetComponent,
        PartnerBasicDataComponent,
        PartnerProductsComponent,
        PickupAddressComponent,
        ProductLinkUploaderWndComponent
    ],
    exports: [
        PartnerGridComponent,
        PartnerScreen,
        PartnerSheetComponent,
        PartnerBasicDataComponent,
        PartnerProductsComponent,
        PickupAddressComponent,
        ProductLinkUploaderWndComponent
    ],
    providers: [],
    entryComponents: [PartnerSheetComponent, PickupAddressComponent, ProductLinkUploaderWndComponent]
})
export class PartnerModule {}
