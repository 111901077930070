import { Component, Inject, Input, Output } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"

import { of, Subject } from "rxjs"
import { map, shareReplay, switchMap, take } from "rxjs/operators"

import { ToastService } from "@anzar/core"

import { Category, CategoryMargin, CategoryRepo, ExternalCategoryRepo } from "@backend/category.api"

import { PartnerService } from "../common.module"

@Component({
    selector: ".eur-category-form",
    templateUrl: "./category-form.component.pug"
})
export class CategoryFormComponent {
    public readonly merchants = this.partnerSvc.merchants$

    public readonly form = new FormGroup({
        id: new FormControl(),
        parent_id: new FormControl(),
        name: new FormControl(),
        margins: new FormArray([])
    })

    public get marginControls() {
        return (this.form.get("margins") as FormArray).controls
    }

    @Input()
    public set model(val: Category) {
        if (this._model !== val) {
            this._model = val
            this._reset(val)
        }
    }
    public get model(): Category {
        return this._model
    }
    public _model: Category

    @Output()
    public readonly onSave = new Subject<Category>()

    private _externalCats = new Subject<number>()
    public readonly externalCategories$ = this._externalCats.pipe(
        switchMap(id => {
            if (!id) {
                return of([])
            }
            return this.extCatRepo.search(
                {
                    filter: {
                        category_id: id
                    },
                    order: { "partner.name": "asc", full_name: "asc" }
                },
                { loadFields: ["id", "partner_id", "full_name", { partner: ["name"] }] }
            )
        }),
        shareReplay(1)
    )

    public constructor(
        @Inject(CategoryRepo) public readonly categoryRepo: CategoryRepo,
        @Inject(ToastService) public readonly toast: ToastService,
        @Inject(ExternalCategoryRepo) private readonly extCatRepo: ExternalCategoryRepo,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService
    ) {
        partnerSvc.merchants$.pipe(take(1)).subscribe(merchants => {
            const marray = this.form.get("margins") as FormArray
            for (const merch of merchants) {
                marray.push(
                    new FormGroup({
                        merchant: new FormControl(merch.id),
                        wslp: new FormControl(),
                        msrp: new FormControl(),
                        rtlp: new FormControl(),
                        commission_percent: new FormControl(),
                        extra_price: new FormControl()
                    })
                )
            }
        })
    }

    public doSave() {
        const data = this.form.value
        this.categoryRepo
            .save({ data })
            .pipe(this.toast.handleSave({ align: "bottom center" }))
            .subscribe(category => {
                this.onSave.next(category)
            })
    }

    public doCancel() {
        this._reset(this._model)
    }

    protected _reset(model: Category) {
        const margins = model.id ? this.categoryRepo.get_margins({ category_id: model.id }) : of<CategoryMargin[]>([])

        this._externalCats.next(model?.id)
        margins
            .pipe(
                switchMap(margins =>
                    this.merchants.pipe(
                        map(merchants =>
                            merchants.map(merchant => {
                                const margin = margins.find(margin => margin.partner_id == merchant.id)
                                return {
                                    merchant: merchant.id,
                                    wslp: margin?.wslp,
                                    msrp: margin?.msrp,
                                    rtlp: margin?.rtlp,
                                    commission_percent: margin?.commission_percent,
                                    extra_price: margin?.extra_price
                                }
                            })
                        )
                    )
                )
            )
            .subscribe(margins => {
                this.form.reset({
                    id: model?.id,
                    parent_id: model?.parent_id,
                    name: model?.name,
                    margins: margins
                })
            })
    }
}
