import { AfterViewInit, Component, Inject } from "@angular/core"
import { FormControl } from "@angular/forms"

import { BehaviorSubject } from "rxjs"

import { Destructible, LoadFields, ToastService } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"

import { SimilarProduct, SimilarProductRepo, SimilarProductRepoSource } from "@backend/supplier.api"

const FIELDS: LoadFields<SimilarProduct> = [
    "id",
    {
        similars: [
            {
                supplier_product: [
                    "id",
                    "product_id",
                    "partner_entity_id",
                    "name",
                    "manufacturer_sku",
                    "source_sku",
                    "source_sku2",
                    "eans",
                    { partner: ["name", "sequence"] }
                ]
            },
            { ignore: ["fields", "totally"] }
        ]
        // products: [
        //     "id", "partner_entity_id", "name", "manufacturer_sku", "source_sku", "source_sku2", "eans",
        //     { partner: ["name", "sequence"] }
        // ]
    }
]

@Component({
    selector: "eur-similar-products-screen",
    templateUrl: "./similar-products.screen.pug"
})
export class SimilarProductsScreen extends Destructible implements AfterViewInit {
    public readonly canManageProducts$ = this.authSvc.hasPermission("product.manage")
    public readonly productsReady$ = new BehaviorSubject(false)
    public readonly filter$ = new BehaviorSubject(null)
    public readonly fields = FIELDS
    public readonly filterInput = new FormControl()

    public constructor(
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(SimilarProductRepo) public readonly repo: SimilarProductRepo,
        @Inject(SimilarProductRepoSource) public readonly source: SimilarProductRepoSource
    ) {
        super()

        this.destruct.subscription(this.filterInput.valueChanges).subscribe(filter => {
            let newFilter: any = null
            if (filter) {
                const result = []
                if ("sku" in filter) {
                    result.push({ "similars.supplier_product.manufacturer_sku": filter["sku"] })
                    result.push({ "similars.supplier_product.source_sku": filter["sku"] })
                    result.push({ "similars.supplier_product.source_sku2": filter["sku"] })
                }

                if ("name" in filter) {
                    result.push({ "similars.supplier_product.name": filter["name"] })
                }

                if (result.length) {
                    newFilter = { or: result }
                }
            }

            this.filter$.next(newFilter)
        })
    }

    public ngAfterViewInit(): void {
        this.doReload()
    }

    public doReload() {
        this.productsReady$.next(false)
        this.repo
            .update()
            .pipe(this.toast.catchError())
            .subscribe(() => {
                this.productsReady$.next(true)
            })
    }

    public doResetIgnores() {}
}
